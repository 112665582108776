<script>
import PageHeader from "@/components/page-header";
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('platforms.configuration'),
      items: [],
      pagination: {
        path: "",
        total: 0,
        totalPages: 0,
        page: 1,
        pageSize: 10,
        nextPages: [],
        backPages: []
      }
    };
  },
  methods: {
    configureDataNextModule: function (id) {
      if (this.$route.query.data) {
        var rs = JSON.parse(atob(this.$route.query.data))
        if (id != null) {
          rs.platformId = id
        }

        return btoa(JSON.stringify(rs))
      }
    },
  },
  mounted() {
    document.getElementById("preloader").style.display = "none";
    document.getElementById("status").style.display = "none";
    BredcrumpManagerEncode(this, { path: this.$route.path, title: 'platforms.configuration' })
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12" style="padding: 0!important;">
      <PageHeader :title="title" />
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/update', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.platformEdit") }}</router-link>
              </div>
              <div class="col-3">

                <router-link :to="{ path: '/platform/detail/module', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.module")
      }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/currency', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.currency")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/country', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.country")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/languages', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.languages")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/permission', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.permissions")
      }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/invoice/type', query: { data: configureDataNextModule(null) } }">{{
        $t("sidebar.orderType")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link
                  :to="{ path: '/platform/detail/invoice/status', query: { data: configureDataNextModule(null) } }">{{
        $t("sidebar.orderStatus")
      }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/product/type', query: { data: configureDataNextModule(null) } }">{{
        $t("sidebar.productType")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link
                  :to="{ path: '/platform/detail/product/price/type', query: { data: configureDataNextModule(null) } }">{{
        $t("sidebar.productPriceType")
      }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/tax', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.tax")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/brands', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.brands")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/category', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.category")
      }}</router-link>
              </div>
              <div class="col-3">
                <router-link :to="{ path: '/platform/detail/tags', query: { data: configureDataNextModule(null) } }">{{
        $t("platforms.list.tags")
      }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link style="color: red;"
                  :to="{ path: '/platform/detail/products', query: { data: configureDataNextModule(null) } }">{{
                  $t("platforms.list.products")
                  }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link style="color: red;"
                  :to="{ path: '/platform/detail/holiday', query: { data: configureDataNextModule(null) } }">{{
                  $t("sidebar.holiday") }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link
                            :to="{ path: '/platform/detail/agent', query: { data: configureDataNextModule(null) } }">{{
                              $t("sidebar.agent") }}</router-link>
              </div>
              <div class="col-3">
                <router-link
                            :to="{ path: '/platform/detail/client', query: { data: configureDataNextModule(null) } }">{{
                              $t("sidebar.clients") }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-3">
                <router-link style="color: red;"
                            :to="{ path: '/platform/detail/invoice', query: { data: configureDataNextModule(null) } }">{{
                              $t("sidebar.invoice") }}</router-link>
              </div>
              <div class="col-3">
                <router-link
                            style="color: red;"
                            :to="{ path: '/platform/detail/delivery', query: { data: configureDataNextModule(null) } }">{{
                              $t("sidebar.delivery")
                            }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>